import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import '../theme/global.scss';
import '../theme/normalize.css';

const HeaderComponent = dynamic(() => import('../components/HeaderComponent/Header'));
const Popup = dynamic(() => import('../components/Popup'));
const FooterComponent = dynamic(() => import('../components/Footer/Footer'));

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  return (
    <>
      <HeaderComponent path={router.pathname} />
      <Popup />
      <Component {...pageProps} router={router} />
      <FooterComponent path={router.pathname} />
    </>
  )
}

export default MyApp
